import { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { AdminUser, ErrorType, UserAccount, UserBillingAddress } from '../../types';
import {
    DateFormatTypeEnum,
    capitalizeWords,
    formatDinero,
    formatTimeStamp,
    getDinero,
    joinNameParts
} from '../../utilities';
import StatusButton from '../common/status-button';

import { FormattedPhoneNumber, manageUserEditFormUseStyles } from '../manage-user/editForm';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import Box from '@material-ui/core/Box';
import storeContext from '../../contexts/store-context';
import { B2BOrderPayoutPaymentGatewayEnum } from '../../types/b2bPayout';

export const TransactionTypeEnum = ['CREDIT', 'DEBIT'] as const;
export const TransactionStatusEnum = ['SUCCESS', 'FAILED', 'ON_HOLD'] as const;
export const FundReasonEnum = ['REFUND_CASHBACK', 'PAYOUT', 'BANK_TRANSFER', 'ADMIN_CREDIT'] as const;

export type UserFundTransaction = {
    id: number;
    account?: UserAccount;
    adminAccount?: AdminUser;
    orderId?: number;
    transactionType: typeof TransactionTypeEnum[number];
    transactionStatus: typeof TransactionStatusEnum[number];
    amount: number;
    balance: number;
    fundReason?: typeof FundReasonEnum[number] | null;
    externalPaymentId?: string | null;
    paymentGateway?: typeof B2BOrderPayoutPaymentGatewayEnum[number] | null;
    createdAt: string;
    updatedAt: string;
};

type UserAccountWithBillingAddress = Partial<UserAccount> & { billingAddresses?: Array<UserBillingAddress> };

type Props = {
    user: UserAccountWithBillingAddress;
    onCancel: () => void;
};

type State = {
    isApproving: boolean;
    isRejecting: boolean;
    user: UserAccountWithBillingAddress | null;
    fundBalance: number;
    fundTransactions: UserFundTransaction[];
};

const DEFAULT_STATE: State = {
    isApproving: false,
    isRejecting: false,
    user: null,
    fundBalance: 0,
    fundTransactions: []
};

const NULL_TEXT = 'Information not available';

export const BusinessUserFundTransactions = (props: Props) => {
    const classes = manageUserEditFormUseStyles();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const { manageBusinessUserAction } = storeContext();
    const { onCancel } = props;

    const [state, setState] = useState<State>({ ...DEFAULT_STATE, user: props.user });

    useEffect(() => {
        setState((prevState) => ({ ...prevState, user: props.user }));
        fetchFundBalance();
        fetchFundTransactions();
    }, [props.user]);

    const fetchFundTransactions = async () => {
        const userId = props.user.userId;

        try {
            const apiResponse = await apiService.get(
                `/api/admin/manage-fund-transactions/user/${userId}/get-fund-transactions`
            );
            const response = apiResponse.parsedBody;
            const transactions = response.data as UserFundTransaction[];
            if (transactions) {
                setState((prevState) => ({
                    ...prevState,
                    fundTransactions: transactions
                }));
            }
        } catch (e: ErrorType) {
            toasterContext.setToaster({ message: e.message, severity: 'error' });
            return null;
        }
    };

    const getTransactionDetails = (item: UserFundTransaction ) => {
        const accountName = item.account?.firstName ? ` (${capitalizeWords(joinNameParts(item.account) || '')})` : '';
        const adminName = item.adminAccount?.firstName
            ? ` (${capitalizeWords(joinNameParts(item.adminAccount) || '')})`
            : '';
        
            if (item.orderId) {
                return item.fundReason === 'REFUND_CASHBACK' ? `Refund for Order #${item.orderId}` : `Order #${item.orderId}`;
            } 
            switch (item.fundReason) {
                case 'PAYOUT':
                    return `Payout by - ${adminName} ${item.adminAccount?.email}`;
                case 'ADMIN_CREDIT':
                        return `Credit by - ${adminName} ${item.adminAccount?.email}`;
                case 'BANK_TRANSFER':
                    return 'Transfer to Bank Account';
                default:
                    return `Fund transfer: ${item.account?.email}${accountName}`;
            }
    };

    const headCells: Array<HeadCell> = [
        { id: 'transactionId', label: 'Tx #' },
        { id: 'transactionType', label: 'Type' },
        { id: 'amount', label: 'Amount' },
        { id: 'createdAt', label: 'Date' },
        { id: 'transactionStatus', label: 'Transaction Status' },
        { id: 'details', label: 'Details' }
    ];

    const tableRows: TableResultRow[] = state.fundTransactions.map((item) => {

        const data: TableResultRow = {
            id: {
                text: item.id.toString(),
                align: 'left'
            },
            transactionId: {
                text: `${item.id}`,
                align: 'left'
            },
            transactionType: {
                text: item.transactionType === 'CREDIT' ? 'Fund Credit' : 'Fund Debit',
                align: 'left'
            },
            amount: {
                text: item.amount.toString(),
                align: 'left',
                element: (
                    <Typography
                        color={
                            item.transactionType === 'CREDIT' ||
                            item.fundReason === 'PAYOUT' ||
                            item.fundReason === 'REFUND_CASHBACK'
                                ? 'secondary'
                                : 'error'
                        }
                    >
                        {item.transactionType === 'CREDIT' ||
                        item.fundReason === 'PAYOUT' ||
                        item.fundReason === 'REFUND_CASHBACK'
                            ? '+'
                            : '-'}
                        &nbsp;{formatDinero(getDinero(item.amount))}
                    </Typography>
                )
            },
            createdAt: {
                text: formatTimeStamp(item.createdAt, DateFormatTypeEnum.MM_DD_YYYY) || '',
                align: 'left'
            },
            transactionStatus: {
                text: item?.transactionStatus === 'ON_HOLD' ? 'PENDING' : item?.transactionStatus,
                align: 'left',
                element: (
                    <Typography
                        color={
                            item.transactionStatus === 'SUCCESS'
                                ? 'secondary'
                                : item.transactionStatus === 'ON_HOLD'
                                ? 'textSecondary'
                                : 'error'
                        }
                    >
                        {item?.transactionStatus === 'ON_HOLD' ? 'PENDING' : item?.transactionStatus}
                    </Typography>
                )
            },
            details: {
                text: getTransactionDetails(item),
                align: 'left'
            }
        };

        return data;
    });

    const fetchFundBalance = async () => {
        const userId = props.user.userId;
        if (!userId) {
            return;
        }

        const fundBalance = await manageBusinessUserAction()?.getFundBalance(userId);

        setState((prevState) => ({
            ...prevState,
            fundBalance: fundBalance || 0
        }));
    };

    const name = joinNameParts(state.user);

    return (
        <Container className={classes.mainContainer}>
            <Grid container={true} className={classes.inner}>
                <Grid item={true} xs={12} className={classes.form}>
                    <Grid container={true} alignItems="center" justifyContent="space-between">
                        <Typography className={classes.heading}>Business User Fund Transactions</Typography>

                        <StatusButton
                            options={[
                                {
                                    type: 'APPROVAL_PENDING',
                                    text: 'Pending',
                                    color: 'warning'
                                },
                                {
                                    type: 'REVOKED',
                                    text: 'Access Revoked',
                                    color: 'danger'
                                },
                                {
                                    type: 'ON_WATCH',
                                    text: 'ON WATCH',
                                    color: 'warning'
                                }
                            ]}
                            type={state.user?.accessStatus}
                        />
                    </Grid>

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                User Id:
                            </Typography>
                            <Typography component="span">#{state.user?.userId}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Name:
                            </Typography>
                            <Typography component="span">{name}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Email:
                            </Typography>
                            <Typography component="span">{state.user?.email || NULL_TEXT}</Typography>
                        </Grid>

                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Mobile number:
                            </Typography>
                            <FormattedPhoneNumber phone={state?.user?.phone} country={state?.user?.country} />
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} className={classes.details}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                Company Name:
                            </Typography>
                            <Typography component="span">
                                <strong>{state.user?.companyName || NULL_TEXT}</strong>
                            </Typography>
                        </Grid>

                        {
                            <Grid item={true} xs={12} className={classes.staticFields}>
                                <Typography color="textSecondary" component="span">
                                    Position:
                                </Typography>
                                <Typography component="span">{state.user?.position || NULL_TEXT}</Typography>
                            </Grid>
                        }

                        {state.user?.taxId && (
                            <Grid item={true} xs={12} className={classes.staticFields}>
                                <Typography color="textSecondary" component="span">
                                    Tax Id:
                                </Typography>
                                <Typography component="span">{state.user?.taxId || NULL_TEXT}</Typography>
                            </Grid>
                        )}
                    </Grid>

                    <Box my={4}>
                        <Grid item={true} xs={12} className={classes.staticFields}>
                            <Typography color="textSecondary" component="span">
                                CardMoola fund balance:
                            </Typography>
                            <Typography component="span">
                                <strong>{formatDinero(getDinero(state.fundBalance))}</strong>
                            </Typography>
                        </Grid>
                    </Box>

                    <TableComponent
                        header="Fund Transactions"
                        showCheckbox={false}
                        showPaginator={{ bottom: true }}
                        showSearch={true}
                        rows={tableRows}
                        headCells={headCells}
                        fillEmptyRows={false}
                        keyField="id"
                    />

                    <Grid item={true} xs={12} className={classes.footer}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                                onCancel();
                                setState(DEFAULT_STATE);
                            }}
                            disabled={state.isApproving || state.isRejecting}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BusinessUserFundTransactions;
