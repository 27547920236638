import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import CachedIcon from '@material-ui/icons/Cached';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import useAdminStyles from '../styles';
import { useApiService } from '../../contexts/api-service-context';
import { useToasterData } from '../../contexts/toaster-context';
import { useUserService } from '../../contexts/user-context';
import { ErrorType, UserAccount } from '../../types';
import { joinNameParts } from '../../utilities';
import HasPermission from '../../utilities/can';
import TableComponent, { HeadCell, TableResultRow } from '../common/table-component';
import UserAvatar from '../common/user-avatar';
import BusinessUserFundTransactions from './fund-transactions';
import StatusButton from '../common/status-button';
import storeContext from '../../contexts/store-context';
import { useStyles } from '../manage-user';

type State = {
    isRefreshing: boolean;
    isLoading: boolean;
    renderEditForm: boolean;
    menuAnchorEle: HTMLElement | null;
    activeUser: UserAccount | null;
    selectedUserIds: Array<number>;
    users: Array<UserAccount>;
};

const ManageFundTransactions = () => {
    const classes = useStyles();
    const adminClasses = useAdminStyles();

    const history = useHistory();
    const apiService = useApiService();
    const toasterContext = useToasterData();
    const userService = useUserService();
    const { appAction, manageBusinessUserAction } = storeContext();

    const canRead = HasPermission(userService.user, 'MANAGE_FUND_TRANSACTIONS', 'READ'); 

    useEffect(() => {
        if (!canRead) {
            history.push('/dashboard');
            return;
        }
        appAction()?.renderFullHeader();
        getAllBusinessUsers();
    }, []);

    const [state, setState] = useState<State>({
        users: [],
        isLoading: false,
        isRefreshing: false,
        renderEditForm: false,
        menuAnchorEle: null,
        activeUser: null,
        selectedUserIds: []
    });

    const getAllBusinessUsers = async (isRefreshing?: boolean) => {
        setState((prevState) => ({ ...prevState, isLoading: !isRefreshing, isRefreshing: !!isRefreshing }));
        const users = await manageBusinessUserAction()?.getAllBusinessUsers();
        setState((prevState) => ({ ...prevState, isLoading: false, isRefreshing: false, users: users || [] }));
    };

    const headCells: Array<HeadCell> = [
        { id: 'userId', label: 'User Id' },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'companyName', label: 'Company Name' },
        { id: 'status', label: 'Status', align: 'left' },
        { id: 'options', label: '', align: 'right' }
    ];

    const tableRows: Array<TableResultRow> = state.users.map((user) => {
        const name = joinNameParts(user) || '-';

        return {
            id: {
                text: user.userId?.toString() || ''
            },
            userId: {
                text: `#${user.userId?.toString()}` || '',
                align: 'left'
            },
            name: {
                align: 'left',
                text: name,
                element: (
                    <Grid item={true} className={classes.userAvatarOuter}>
                        <UserAvatar className={classes.userAvatar} user={user} />
                        <Typography>{name}</Typography>
                    </Grid>
                )
            },
            email: {
                align: 'left',
                text: user.email || '-'
            },
            companyName: {
                align: 'left',
                text: user.companyName || '-'
            },
            status: {
                align: 'left',
                text: '',
                element: (
                    <StatusButton
                        options={[
                            {
                                type: 'APPROVAL_PENDING',
                                text: 'Pending',
                                color: 'warning'
                            },
                            {
                                type: 'REVOKED',
                                text: 'Revoked',
                                color: 'danger'
                            },
                            {
                                type: 'ON_WATCH',
                                text: 'ON WATCH',
                                color: 'warning'
                            }
                        ]}
                        type={user.accessStatus}
                    />
                )
            },
            options: {
                align: 'right',
                text: '',
                helperText: user.updatedBy?.userId && `updated by - ${user.updatedBy?.firstName ? joinNameParts(user.updatedBy) : user.updatedBy?.email || ''
                    }${user.updatedBy?.updatedAt && ' - '}${user.updatedBy?.updatedAt && moment(new Date(user.updatedBy?.updatedAt)).fromNow()
                    }` || undefined,
                element: (canRead && (
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setState((prevState) => ({
                                ...prevState,
                                menuAnchorEle: e.currentTarget,
                                activeUser: user
                            }));
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                )) ||
                    undefined
            }
        };
    });

    const headerComponent = (
        <Grid item={true} xs={12} className={classes.headerComponent}>
            <Typography className={classes.tableHeading}>Manage Business Fund Transactions</Typography>
            <Button
                onClick={(e) => getAllBusinessUsers(true)}
                size="small"
                variant="contained"
                disabled={state.isRefreshing}
            >
                <CachedIcon className={state.isRefreshing && 'rotation-loading' || undefined} />
                &nbsp;Refresh
            </Button>
        </Grid>
    )

    const handleRowClick = (userId: string) => {
        if (!canRead) {
            return;
        }

        setState((prevState) => ({
            ...prevState,
            renderEditForm: true,
            activeUser: prevState.users.find((u) => u.userId === +userId) || null
        }));
    };

    const handleMenuClose = () => {
        setState((prevState) => ({ ...prevState, menuAnchorEle: null, activeUser: null }));
    };
 
    return (
        <Grid item={true} xs={12} className={adminClasses.root}>
            <Container className={adminClasses.container}>
                {(state.activeUser && state.renderEditForm && (
                    <BusinessUserFundTransactions
                        user={state.activeUser}
                        onCancel={() =>
                            setState((prevState) => ({ ...prevState, renderEditForm: false, activeUser: null }))
                        }
                    />
                )) ||
                    null}

                <Menu anchorEl={state.menuAnchorEle} open={!!state.menuAnchorEle} onClose={handleMenuClose}>
                    <MenuItem
                        disabled={!canRead}
                        onClick={() => {
                            if (state.activeUser) {
                                handleRowClick((state.activeUser?.userId || -1).toString())
                            }
                            setState(prevState => ({ ...prevState, menuAnchorEle: null }));
                        }}
                    >
                        View
                    </MenuItem>
                </Menu>

                <TableComponent
                    rowHover={canRead}
                    headerComponent={headerComponent}
                    showCheckbox={false}
                    showPaginator={{ bottom: true }}
                    showSearch={true}
                    isLoading={state.isLoading}
                    rows={tableRows}
                    headCells={headCells}
                    fillEmptyRows={false}
                    keyField="id"
                    rowTooltip={(canRead && 'Click to view') || undefined}
                    onRowClick={handleRowClick}
                    overrideClasses={{
                        toolbarClass: classes.toolbar
                    }}
                />
            </Container>
        </Grid>
    );
};

export default ManageFundTransactions;
