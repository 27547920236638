import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { DateRangeDelimiter, StaticDateRangePicker, TimePicker } from '@material-ui/pickers';

import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { AppTextField } from '../../common/app-textfield';
import { B2BOrderTypeEnum, OrderItemRecipientDeliveryTypeEnum, OrderItemRecipientStatusEnum, OrderStatusEnum } from '../../../types';

import moment from 'moment';
import { PaymentStatusEnum } from '../../../types/payment';
import { OrderUserTypeEnum } from '.';

const useStyles = makeStyles((theme) => ({
    input: {
        minWidth: 220,
        flex: 1,
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            maxWidth: 290
        }
    },
    dateRangePicker: {
        border: `1px solid ${theme.palette.grey['400']}`,
        borderRadius: 4
    },
    break: {
        flexBasis: '100%'
    },
    userTypeDropdown: {
        width: 300,
        marginBottom: 16
    }
}));

type DateOption = {
    offset: number;
    startOf: 'day' | 'month' | 'year';
    label: string;
};
const DATE_OPTIONS: DateOption[] = [
    {
        offset: 0,
        startOf: 'day',
        label: 'Today'
    },
    {
        offset: 1,
        startOf: 'day',
        label: 'Yesterday'
    },
    {
        offset: 7,
        startOf: 'day',
        label: 'Last 7 days'
    },
    {
        offset: 30,
        startOf: 'day',
        label: 'Last 30 days'
    },
    {
        offset: 0,
        startOf: 'month',
        label: 'This month'
    },
    {
        offset: 1,
        startOf: 'month',
        label: 'Last month'
    },
    {
        offset: 0,
        startOf: 'year',
        label: 'This year'
    }
];

export const B2C_USER = 'B2C_USER';
export const B2B_USER = 'B2B_USER';

type State = {
    userId: number | null;
    orderId: number | null;
    orderStatus: 'ALL' | typeof OrderStatusEnum[number];
    emailDeliveryStatus: 'ALL' | typeof OrderItemRecipientStatusEnum[number];
    paymentStatus: 'ALL' | typeof PaymentStatusEnum[number];
    deliveryType: 'ALL' | typeof OrderItemRecipientDeliveryTypeEnum[number];
    dateRange: [string | null, string | null];
    dateOptionSelected: string;
    orderUserType: typeof OrderUserTypeEnum[number];
    orderType: 'ALL' | typeof B2BOrderTypeEnum[number];
};

const DEFAULT_STATE: State = {
    userId: null,
    orderId: null,
    orderStatus: 'ALL',
    emailDeliveryStatus: 'ALL',
    paymentStatus: 'ALL',
    deliveryType: 'ALL',
    dateRange: [null, null],
    dateOptionSelected: 'ALL',
    orderUserType: B2C_USER,
    orderType: 'ALL'
};

type Props = {
    onFilter: (data: State) => void;
};

const OrderHistoryFilter = (props: Props) => {
    const classes = useStyles();

    const [state, setState] = React.useState<State>(DEFAULT_STATE);
    const [orderUserType, setOrderUserType] = React.useState(B2B_USER);

    const onFilterApply = () => {
        props.onFilter(state);
    };

    const onFilterClear = () => {
        setState({ ...DEFAULT_STATE });
        props.onFilter(DEFAULT_STATE);
    };

    const onDateOptionPick = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const dateOptionSelected = e.target.value as State['dateOptionSelected'];

        const dateOption = DATE_OPTIONS.find((option) => option.label === dateOptionSelected);
        if (!dateOption) {
            setState((prevState) => ({
                ...prevState,
                dateOptionSelected,
                dateRange: [null, null]
            }));
            return;
        }

        const { offset, startOf } = dateOption;
        const startDateObj = moment().subtract(offset, startOf).startOf(startOf);
        const endDateObj = startDateObj
            .clone()
            .add(offset === 1 ? 0 : offset, startOf)
            .endOf(startOf);
        console.log({
            start: startDateObj.format('MM/DD/YYYY HH:mm'),
            end: endDateObj.format('MM/DD/YYYY HH:mm')
        });
        setState((prevState) => ({
            ...prevState,
            dateOptionSelected,
            dateRange: [startDateObj.format(), endDateObj.format()]
        }));
    };

    const onDateRangePick = (newValue: [string | null, string | null]) => {
        let endDate = newValue[1] ? moment(newValue[1]).format() : null;

        if (
            newValue[0] &&
            newValue[1] &&
            moment(newValue[0]).format('MM/DD/YYYY HH:mm') === moment(newValue[1]).format('MM/DD/YYYY HH:mm')
        ) {
            endDate = moment(newValue[1]).endOf('day').format();
        }
        setState((prevState) => ({ ...prevState, dateRange: [newValue[0], endDate] }));
    };

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="search-filter-content"
                id="search-filter-header"
            >
                <Typography>Search Filter</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" mb={2} flexWrap="wrap" gridGap="1rem" width="100%">
                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="select-order-user-type-label">User Type</InputLabel>
                        <Select
                            labelId="select-order-user-type-label"
                            id="select-order-user-type-helper"
                            value={state.orderUserType}
                            label="User Type"
                            onChange={(e) => {
                                const userType = e.target.value as typeof OrderUserTypeEnum[number];
                                setState((prevState) => ({ ...prevState, orderUserType: userType }));
                            }}
                        >
                            <MenuItem value={B2C_USER}>B2C User Orders</MenuItem>
                            <MenuItem value={B2B_USER}>B2B User Orders</MenuItem>
                        </Select>
                    </FormControl>
                    <div className={classes.break} />
                    <AppTextField
                        variant="outlined"
                        placeholder="Order ID"
                        size="small"
                        label="Order ID"
                        value={state.orderId || ''}
                        InputLabelProps={{
                            shrink: true
                        }}
                        className={classes.input}
                        onChange={(e) => setState((prevState) => ({ ...prevState, orderId: +e.target.value || null }))}
                    />

                    <AppTextField
                        variant="outlined"
                        placeholder="Created By User Id"
                        size="small"
                        label="Created By User Id"
                        value={state.userId || ''}
                        InputLabelProps={{
                            shrink: true
                        }}
                        className={classes.input}
                        onChange={(e) => setState((prevState) => ({ ...prevState, userId: +e.target.value || null }))}
                    />

                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="order-status-select-label">Order Status</InputLabel>
                        <Select
                            labelId="order-status-select-label"
                            id="order-status-select"
                            value={state.orderStatus}
                            label="Order Status"
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    orderStatus: e.target.value as State['orderStatus']
                                }))
                            }
                        >
                            <MenuItem value={'ALL'}>All Status</MenuItem>
                            {OrderStatusEnum.map((status) => (
                                <MenuItem value={status} key={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {state.orderUserType === 'B2B_USER' && (
                        <FormControl variant="outlined" className={classes.input} size="small">
                            <InputLabel id="order-type-date-filter-label">Order Type</InputLabel>
                            <Select
                                labelId="order-type-date-filter-label"
                                id="order-type-date-filter"
                                value={state.orderType}
                                label="Order Type"
                                onChange={(e) => {
                                    setState((prevState) => ({
                                        ...prevState,
                                        orderType: e.target.value as State['orderType'],
                                    }));
                                }}
                            >
                                <MenuItem value={'ALL'}>All</MenuItem>
                                {B2BOrderTypeEnum.map((orderType) => (
                                    <MenuItem key={orderType} value={orderType}>
                                        {orderType}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="email-status-select-label">Delivery Status</InputLabel>
                        <Select
                            labelId="email-status-select-label"
                            id="email-status-select"
                            value={state.emailDeliveryStatus}
                            label="Delivery Status"
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    emailDeliveryStatus: e.target.value as State['emailDeliveryStatus']
                                }))
                            }
                        >
                            <MenuItem value={'ALL'}>All Status</MenuItem>
                            {OrderItemRecipientStatusEnum.map((status) => (
                                <MenuItem value={status} key={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="payment-status-select-label">Payment Status</InputLabel>
                        <Select
                            labelId="payment-status-select-label"
                            id="payment-status-select"
                            value={state.paymentStatus}
                            label="Payment Status"
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    paymentStatus: e.target.value as State['paymentStatus']
                                }))
                            }
                        >
                            <MenuItem value={'ALL'}>All Status</MenuItem>
                            {PaymentStatusEnum.map((status) => (
                                <MenuItem value={status} key={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div className={classes.break} />

                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="delivery-type-select-label">Delivery Mode</InputLabel>
                        <Select
                            labelId="delivery-type-select-label"
                            id="delivery-type-select"
                            value={state.deliveryType}
                            label="Delivery Mode"
                            onChange={(e) =>
                                setState((prevState) => ({
                                    ...prevState,
                                    deliveryType: e.target.value as State['deliveryType']
                                }))
                            }
                        >
                            <MenuItem value={'ALL'}>All Type</MenuItem>
                            {OrderItemRecipientDeliveryTypeEnum.map((status) => (
                                <MenuItem value={status} key={status}>
                                    {status === 'CODES' ? 'MY CARDS SECTION' : status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.input} size="small">
                        <InputLabel id="date-select-label">Select Date</InputLabel>
                        <Select
                            labelId="date-select-label"
                            id="date-select"
                            value={state.dateOptionSelected}
                            label="Select Date"
                            onChange={onDateOptionPick}
                        >
                            <MenuItem value={'ALL'}>All</MenuItem>
                            {DATE_OPTIONS.map((option) => (
                                <MenuItem key={option.label} value={option.label}>
                                    {option.label}
                                </MenuItem>
                            ))}
                            <MenuItem value={'OTHER'}>Other</MenuItem>
                        </Select>
                    </FormControl>

                    {state.dateOptionSelected === 'OTHER' && (
                        <StaticDateRangePicker
                            displayStaticWrapperAs="desktop"
                            showToolbar={false}
                            value={state.dateRange}
                            onChange={onDateRangePick}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <DateRangeDelimiter> to </DateRangeDelimiter>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                            className={classes.dateRangePicker}
                        />
                    )}

                    {state.dateRange[0] &&
                        (!state.dateRange[1] ||
                            moment(state.dateRange[0])?.format('MM/DD/YYYY') ===
                                moment(state.dateRange[1])?.format('MM/DD/YYYY')) && (
                            <Box>
                                <Typography align="left" variant="body2">
                                    From:
                                </Typography>

                                <TimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    value={state.dateRange[0]}
                                    inputFormat="HH:mm a"
                                    onChange={(date) => {
                                        if (!moment(date).isValid()) {
                                            return;
                                        }
                                        const hours = moment(date).format('HH');
                                        const minutes = moment(date).format('mm');

                                        const startDate = moment(state.dateRange[0])
                                            .set('hours', +hours)
                                            .set('minutes', +minutes);

                                        setState((prevState) => ({
                                            ...prevState,
                                            dateRange: [startDate.format(), state.dateRange[1]]
                                        }));
                                    }}
                                />

                                <Box mt={3} />

                                <Typography align="left" variant="body2">
                                    To:
                                </Typography>

                                <TimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    value={state.dateRange[1]}
                                    inputFormat="HH:mm a"
                                    onChange={(date) => {
                                        if (!moment(date).isValid()) {
                                            return;
                                        }
                                        const hours = moment(date).format('HH');
                                        const minutes = moment(date).format('mm');

                                        const endDate = moment(state.dateRange[1])
                                            .set('hours', +hours)
                                            .set('minutes', +minutes);

                                        setState((prevState) => ({
                                            ...prevState,
                                            dateRange: [state.dateRange[0], endDate.format()]
                                        }));
                                    }}
                                />
                            </Box>
                        )}

                    <Box display="flex" justifyContent="flex-end" gridGap="1rem" width="100%">
                        <Button variant="contained" size="small" onClick={onFilterClear}>
                            Clear
                        </Button>
                        <Button color="primary" size="small" variant="contained" onClick={onFilterApply}>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default OrderHistoryFilter;
